import { privateApi } from "libs/api";
import { Content } from "types/content";

export default class ContentService {
  private static readonly uri = "/admin/update";

  static async requireContent() {
    const res = await privateApi.get<Content>(ContentService.uri);
    return res.data;
  }

  static async requireContentLinkForDownload(path: string) {
    const res = await privateApi.post<{ data: string; message: string }>(
      `${ContentService.uri}/download`,
      {
        path,
      },
    );

    return res.data;
  }

  static async uploadContent(key: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("key", key);

    await privateApi.post(`${ContentService.uri}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static async deleteContent(path: string) {
    await privateApi.post(`${ContentService.uri}/delete`, { path });
  }

  static async updateAllCatalog() {
    const res = await privateApi.post<{ message: string; error: string }>(
      `${ContentService.uri}/run/all-catalog`,
    );

    return res.data;
  }

  static async updateAllPresets() {
    const res = await privateApi.post<{ message: string; error: string }>(
      `${ContentService.uri}/run/all-presets`,
    );

    return res.data;
  }
}
