import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Table } from "components/Table";
import { ButtonIcon } from "components/ButtonIcon";
import { Id } from "types/id";
import { Content as IContent, ContentKeys } from "types/content";
import ContentService from "services/admin/contentService";
import { ToastContext } from "contexts/toastContext";
import { contentStore } from "stores/admin";
import styles from "./styles.module.scss";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface ContentProps {
  field: ContentKeys;
  content: IContent;
  widthValues: number[];
}

export function Content({ field, content, widthValues = [] }: ContentProps) {
  const navigate = useNavigate();
  const { toast } = useContext(ToastContext);
  const onDrop = useCallback(
    (acceptedFiles) => {
      toast
        .promise(ContentService.uploadContent(field, acceptedFiles[0]), {
          pending: "Загрузка файла...",
          success: "Файл успешно загружен!",
          error: "Ошибка при загрузке файла!",
        })
        .then(() => {
          ContentService.requireContent().then((res) => {
            contentStore.content = res;
          });
        });
    },
    [field, toast],
  );
  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, maxFiles: 1 });

  const moveToAboutFile = (id: Id) => {
    navigate(`/admin/content/${id}/about`);
  };

  const moveToRemoveContent = (id: Id) => {
    navigate(`/admin/content/${id}/remove`);
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      <h3 className={styles.title}>{field}</h3>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Переместите файл сюда ...</p>
        ) : (
          <p>Переместите файл сюда, или кликните чтобы выбрать файл</p>
        )}
      </div>
      <Table
        removeCallback={moveToRemoveContent}
        className={styles.tableContainer}
        headers={["Имя", "Путь", "Дата изменения"]}
        values={content[field].map(({ name, path, modified }) => ({ name, path, modified }))}
        widthValues={widthValues}
        fields={["name", "path", "modified"]}
        additionalFeatures={[
          {
            id: 1,
            component: <ButtonIcon className={styles.aboutIcon} iconType='about' />,
            width: 46,
            callback: moveToAboutFile,
          },
        ]}
        removable
      />
    </>
  );
}
