import { createBrowserRouter, Navigate, defer } from "react-router-dom";
import MainPage from "pages/MainPage";
import ColoursPage from "pages/ColoursPage";
import ColourPage from "pages/ColorPage";
import PresetsPage from "pages/EditorPages/PresetsPage";
import PresetPage from "pages/EditorPages/PresetPage";
import UploadPhotoPage from "pages/UploadPhotoPage";
import { UserPhotoPage } from "pages/EditorPages/UserPhotoPage";
import {
  AboutUserPage,
  AddUserPage,
  EditUserPage,
  RemoveUserPage,
  UsersPage,
  UserToManagerPage,
} from "pages/AdminPages/UsersPages";
import {
  AddManagerPage,
  EditManagerPage,
  ManagersPage,
  RemoveManagerPage,
  AboutManagerPage,
} from "pages/AdminPages/ManagersPage";
import { EditPresetPage, PresetsAdminPage } from "pages/AdminPages/PresetsPage";
import { EditPalettePage, SeasonPalettesPage } from "pages/AdminPages/SeasonPalettesPage";
import { AdminPage } from "pages/AdminPages/adminPage";
import { CodePage, LoginPage } from "pages/AdminPages/AuthPages";
import {
  AddOrganisationPage,
  DeniedRemoveOrganisationPage,
  OrganisationsPage,
  RemoveOrganisationPage,
} from "pages/AdminPages/OrganisationPages";
import { EditOrganisationPage } from "pages/AdminPages/OrganisationPages/editOrganisationPage";
import { SeasonsPage } from "pages/SeasonsPage";
import {
  AboutSeasonPage,
  SeasonsPage as AdminSeasonsPage,
  RemoveSeasonPage,
} from "pages/AdminPages/SeasonsPages";
import { ContentPage } from "pages/AdminPages/Content";
import AboutContentPage from "pages/AdminPages/Content/aboutContentPage";
import RemoveContentPage from "pages/AdminPages/Content/removeContentPage";
import { APP } from "constants/index";
import { SeasonsService } from "services/admin/seasonsService";
import { Id } from "types";

function AuthGuard({ children }) {
  const token = localStorage.getItem(`${APP}_token`);

  if (!token) return <Navigate to='/admin/login' />;

  return children;
}

const Router = createBrowserRouter([
  { path: "/", element: <MainPage /> },
  { path: "/colours", element: <ColoursPage /> },
  { path: "/colours/:colourName", element: <ColourPage /> },
  { path: "/editor/presets", element: <PresetsPage /> },
  { path: "/editor/presets/:presetName", element: <PresetPage /> },
  { path: "/editor/presets/user-photo", element: <UserPhotoPage /> },
  { path: "/seasons", element: <SeasonsPage /> },

  { path: "/upload-photo", element: <UploadPhotoPage /> },
  // админка
  {
    path: "/admin",
    element: (
      <AuthGuard>
        <AdminPage />
      </AuthGuard>
    ),
    children: [
      {
        path: "/admin/content",
        element: <ContentPage />,
        children: [
          { path: "/admin/content/:id/about", element: <AboutContentPage /> },
          { path: "/admin/content/:id/remove", element: <RemoveContentPage /> },
        ],
      },
      {
        path: "/admin/users",
        element: <UsersPage />,
        children: [
          { path: "/admin/users/add", element: <AddUserPage /> },
          { path: "/admin/users/:id/edit", element: <EditUserPage /> },
          { path: "/admin/users/:id/remove", element: <RemoveUserPage /> },
          { path: "/admin/users/:id/about", element: <AboutUserPage /> },
          { path: "/admin/users/:id/to-manager", element: <UserToManagerPage /> },
        ],
      },
      {
        path: "/admin/managers",
        element: <ManagersPage />,
        children: [
          { path: "/admin/managers/add", element: <AddManagerPage /> },
          { path: "/admin/managers/:id/edit", element: <EditManagerPage /> },
          { path: "/admin/managers/:id/remove", element: <RemoveManagerPage /> },
          { path: "/admin/managers/:id/about", element: <AboutManagerPage /> },
        ],
      },
      {
        path: "/admin/presets",
        element: <PresetsAdminPage />,
        children: [{ path: "/admin/presets/:id/edit", element: <EditPresetPage /> }],
      },
      {
        path: "/admin/season-palettes",
        element: <SeasonPalettesPage />,
        children: [{ path: "/admin/season-palettes/:id/edit", element: <EditPalettePage /> }],
      },
      {
        path: "/admin/organisations",
        element: <OrganisationsPage />,
        children: [
          {
            path: "/admin/organisations/add",
            element: <AddOrganisationPage />,
          },
          {
            path: "/admin/organisations/:id/remove",
            element: <RemoveOrganisationPage />,
          },
          {
            path: "/admin/organisations/:id/edit",
            element: <EditOrganisationPage />,
          },
          {
            path: "/admin/organisations/:id/remove/denied",
            element: <DeniedRemoveOrganisationPage />,
          },
        ],
      },
      {
        path: "/admin/seasons",
        element: <AdminSeasonsPage />,
        children: [
          {
            path: "/admin/seasons/:id/about",
            element: <AboutSeasonPage />,
            loader: async ({ params }) => {
              const seasonPromise = SeasonsService.requireSeasonById(params.id as Id);
              return defer({ seasonPromise });
            },
          },
          {
            path: "/admin/seasons/:id/remove",
            element: <RemoveSeasonPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/admin/login",
    element: <LoginPage />,
  },
  {
    path: "/admin/code",
    element: <CodePage />,
  },
]);

export default Router;
