import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import ContentService from "services/admin/contentService";
import { Loader } from "components/Loader";
import { contentStore } from "stores/admin";
import { UpdateAllCatalog, UpdateAllPresets } from "features/admin";
import { Header } from "../components";
import { Content } from "./Content";

function ContentPage() {
  const [pending, setPending] = useState(true);

  useEffect(() => {
    ContentService.requireContent()
      .then((res) => {
        contentStore.content = res;
      })
      .finally(() => setPending(false));
  }, []);

  if (pending) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const renderContent = () => {
    if (!contentStore.content) return null;

    return (
      <div>
        <Content widthValues={[80, 300]} content={contentStore.content} field='Цвета' />
        <Content widthValues={[80, 300]} content={contentStore.content} field='Каталог' />
        <Content widthValues={[80, 300]} content={contentStore.content} field='Текстуры' />
        <Content widthValues={[160, 300]} content={contentStore.content} field='Внешние пресеты' />
        <Content
          widthValues={[160, 300]}
          content={contentStore.content}
          field='Внутренние пресеты'
        />
      </div>
    );
  };

  return (
    <>
      <Header
        title='Контент'
        uploadCVSFile={<UpdateAllCatalog />}
        addEntityFeature={<UpdateAllPresets />}
      />
      {renderContent()}
      <Outlet />
    </>
  );
}

const ContentPageComponent = observer(ContentPage);
export { ContentPageComponent as ContentPage };
