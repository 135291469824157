import { useNavigate } from "react-router-dom";
import { RemoveContent } from "features/admin";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import styles from "./styles.module.scss";

export default function RemoveContentPage() {
  const navigate = useNavigate();

  const navigateToContentPage = () => {
    navigate("/admin/content");
  };

  return (
    <Modal open width={430} className={styles.removeContent} close={navigateToContentPage}>
      <h3>Вы действительно хотите удалить файл?</h3>
      <div className={styles.removeContent_actions} style={{ justifyContent: "center" }}>
        <RemoveContent />
        <Button className={styles.removeContent_actions_cancel} onClick={navigateToContentPage}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
}
