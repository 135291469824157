import { makeAutoObservable } from "mobx";
import { Id } from "types/id";
import { Content, ContentItem } from "types/content";

class ContentStore {
  private _content: Content = Object.create(null);

  constructor() {
    makeAutoObservable(this);
  }

  set content(content) {
    this._content = content;
  }

  get content() {
    return this._content;
  }

  requireContentById(id: Id): ContentItem | null {
    const keys = Object.keys(this._content);
    let res: any = null;

    for (const key of keys) {
      const found = this._content[key].find((c) => c.name === id);

      if (found) {
        res = found;
        break;
      }
    }

    return res;
  }
}

const contentStore = new ContentStore();
export { contentStore };
