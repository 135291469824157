import { useContext, useState } from "react";
import { AxiosError } from "axios";
import { Button } from "components/Button";
import ContentService from "services/admin/contentService";
import { ToastContext } from "contexts/toastContext";

export function UpdateAllPresets() {
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);

  const updateAllPresets = () => {
    setPending(true);

    ContentService.updateAllPresets()
      .then(() => {
        toast.success("Обновление пресетов успешно запущено!");
      })
      .catch((err: AxiosError) => {
        if (
          err?.response?.status === 422 &&
          typeof (err?.response?.data as any)?.error === "string" &&
          (err?.response?.data as any)?.error === "the update is already running"
        ) {
          toast.error(
            "Ошибка. Процесс обновления пресетов уже запущен. Подождите его окончания...",
          );
        } else {
          toast.error("Ошибка при запуске процедуры обновления пресетов!");
        }
      })
      .finally(() => setPending(false));
  };

  return (
    <Button disabled={pending} onClick={updateAllPresets}>
      Обновить пресеты
    </Button>
  );
}
