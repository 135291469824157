import { ReactNode, useCallback, useMemo } from "react";
import ReactPaginate from "react-paginate";
import classNames from "classnames";
import { Loader } from "components/Loader";
import { Id } from "types";
import { ButtonIcon, imgCollection } from "../ButtonIcon";
import styles from "./table.module.scss";

interface TableProps {
  headers: string[];
  values: any[];
  editable?: boolean;
  removable?: boolean;
  widthValues: number[];
  editCallback?: (id: Id) => void;
  removeCallback?: (id: Id) => void;
  additionalFeatures?: {
    width: number;
    component: JSX.Element;
    callback?: (id: Id) => void;
    id: Id;
  }[];
  pending?: boolean;
  paginate?: boolean;
  pageCount?: number;
  onPageChange?: (selectedItem: { selected: number }) => void;
  fields: string[];
  currentPage?: number;
  removableIcon?: keyof typeof imgCollection;
  deleteAtFieldName?: string;
  restoreEntityCallback?: (id: Id) => ReactNode;
  className?: string;
}

function Table({
  headers,
  values,
  editable,
  removable,
  widthValues = [],
  editCallback,
  removeCallback,
  additionalFeatures = [],
  pending,
  paginate,
  pageCount,
  onPageChange,
  fields,
  currentPage,
  removableIcon,
  deleteAtFieldName,
  restoreEntityCallback,
  className,
}: TableProps) {
  const edit = useCallback(
    (value: any) => () => {
      if (!editCallback) return;

      editCallback(value[0]);
    },
    [editCallback],
  );

  const remove = useCallback(
    (value: any) => () => {
      if (!removeCallback) return;

      removeCallback(value[0]);
    },
    [removeCallback],
  );

  const renderHeaders = useMemo(
    () =>
      headers.map((h, i) => (
        <th align={i === 0 ? "center" : "left"} className={styles.table_th} key={h}>
          {h}
        </th>
      )),
    [headers],
  );

  const additionalCallback = useCallback(
    (value: any, callback?: (id: Id) => void) => () => {
      if (!callback) return;

      callback(value[0]);
    },
    [],
  );

  const isDeletedField = useCallback(
    (value: any) => {
      if (!deleteAtFieldName) return false;

      if (!value[deleteAtFieldName]) return false;

      return true;
    },
    [deleteAtFieldName],
  );

  const renderValues = useMemo(
    () =>
      values.map((value, index) => (
        <tr
          className={classNames(styles.table_tr, { [styles.deletedField]: isDeletedField(value) })}
          key={index}
        >
          {fields.map((f: string, i) => (
            <td
              style={{ maxWidth: widthValues?.length > 0 ? widthValues[i] : undefined }}
              align='left'
              className={styles.table_td}
              key={i}
            >
              {i === 0 ? <div className={styles.tabletd_id}>{value[f]}</div> : value[f]}
            </td>
          ))}

          <td>
            <div className={styles.icons}>
              {!isDeletedField(value) &&
                additionalFeatures.map((af) => (
                  <div
                    key={af.id}
                    onClick={additionalCallback(Object.values(value) as any[0], af.callback)}
                  >
                    {af.component}
                  </div>
                ))}
              {isDeletedField(value) && restoreEntityCallback
                ? restoreEntityCallback(value.id)
                : null}
              {!isDeletedField(value) && editable ? (
                <ButtonIcon
                  className={styles.table_btn}
                  onClick={edit(Object.values(value) as any[0])}
                  iconType='edit'
                />
              ) : null}
              {!isDeletedField(value) && removable ? (
                <ButtonIcon
                  className={styles.table_btn}
                  onClick={remove(Object.values(value) as any[0])}
                  iconType={removableIcon || "delete"}
                />
              ) : null}
            </div>
          </td>
        </tr>
      )),
    [
      values,
      isDeletedField,
      fields,
      additionalFeatures,
      removable,
      remove,
      removableIcon,
      editable,
      edit,
      widthValues,
      additionalCallback,
      restoreEntityCallback,
    ],
  );

  const renderPaginate = () => {
    if (!paginate || !pageCount || pageCount === 1) return null;

    return (
      <ReactPaginate
        className={styles.table_paginate}
        pageClassName={styles.table_paginatePage}
        activeClassName={styles.table_paginateActive}
        breakClassName={styles.table_paginateBreak}
        nextLabel=''
        previousLabel=''
        pageCount={pageCount}
        onPageChange={onPageChange}
        forcePage={currentPage ? currentPage - 1 : undefined}
      />
    );
  };

  return (
    <>
      <div className={classNames(styles.container, className)}>
        {pending ? (
          <Loader />
        ) : (
          <table width='100%' cellSpacing='0' cellPadding='0' className={styles.table}>
            <thead>
              <tr>{renderHeaders}</tr>
            </thead>
            <tbody>{renderValues}</tbody>
          </table>
        )}
      </div>
      {renderPaginate()}
    </>
  );
}

export { Table };
