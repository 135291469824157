import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContext } from "contexts/toastContext";
import { Button } from "components/Button";
import ContentService from "services/admin/contentService";
import { contentStore } from "stores/admin";

export function RemoveContent() {
  const { id } = useParams();
  const { toast } = useContext(ToastContext);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  const onClick = () => {
    if (!id) throw new Error("id is required");

    const content = contentStore.requireContentById(id);

    if (!content) throw new Error("content is required");

    setPending(true);

    toast
      .promise(ContentService.deleteContent(content.path), {
        pending: "Удаление файла...",
        success: "Файл успешно удалён!",
        error: "Ошибка при удалении файла!",
      })
      .then(() => {
        ContentService.requireContent().then((res) => {
          contentStore.content = res;
        });
        navigate("/admin/content");
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button disabled={pending} onClick={onClick}>
      Удалить
    </Button>
  );
}
