import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import useDownloader from "react-use-downloader";
import { Modal } from "components/Modal";
import { contentStore } from "stores/admin";
import ContentService from "services/admin/contentService";
import { ToastContext } from "contexts/toastContext";
import styles from "./styles.module.scss";

export default function AboutContentPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { percentage, download, isInProgress } = useDownloader();
  const { toast } = useContext(ToastContext);

  if (!id) return null;

  const entity = contentStore.requireContentById(id);

  if (!entity) return null;

  const moveToContentPage = () => {
    navigate("/admin/content");
  };

  const downloadContent = () => {
    if (isInProgress) return;

    ContentService.requireContentLinkForDownload(entity.path).then((res) => {
      toast.promise(download(res.data, entity.name), {
        pending: "Скачивание файла...",
        success: "Файл успешно скачан!",
        error: "Ошибка при скачивании файла!",
      });
    });
  };

  return (
    <Modal open width={430} close={moveToContentPage}>
      <h3 className={styles.entity_title}>О пользователе</h3>
      <div className={styles.entity_container}>
        <div className={styles.entity_aboutItem}>
          <p className={styles.entity_aboutItemLabel}>Название</p>
          <p className={styles.entity_aboutItemValue}>{entity?.name}</p>
        </div>
        <div className={styles.entity_aboutItem}>
          <p className={styles.entity_aboutItemLabel}>Скачать</p>
          <div
            onClick={downloadContent}
            className={classNames(styles.entity_aboutItemValue, styles.entity_download)}
          >
            {entity?.path}
          </div>
          <div className={styles.entity_indicator} style={{ width: `${percentage}%` }} />
        </div>
        <div className={styles.entity_aboutItem}>
          <p className={styles.entity_aboutItemLabel}>Дата изменения</p>
          <p className={styles.entity_aboutItemValue}>{entity?.modified}</p>
        </div>
      </div>
    </Modal>
  );
}
